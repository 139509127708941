import { useEffect, useState} from 'react';
import './App.css';

function App(props) {
  const deltaSymbol = "\u{0394}";
  const asx200Symbol = "^AXJO";
  const asx200Label = "ASX 200";
  var initialStockInfos = props.symbolsAndCounts.map(symbolAndCount => {return {key: symbolAndCount.symbol, stockInfo: null}});
  const [stockInfos, setStockInfos] = useState(initialStockInfos);
  const [asx200StockInfo, setAsx200StockInfo] = useState(null);
  const [totalOwnedValue, setTotalOwnedValue] = useState(null);
  const [totalDeltaSuperValue, setTotalDeltaSuperValue] = useState(null);
  const svcUrl = "/ax.py";
  const superColor = "#7b7b7b";
  const superPositiveColor = "green";
  const superNegativeColor = "#803e3e";
  const superStyle = {
    whiteSpace: "nowrap",
    color: superColor,
    fontSize:"60%"
  };

  const currencyFormatter = new Intl.NumberFormat('en-AU', {
    style: 'currency',
    currency: 'AUD',
  });

  function calculateStockInfoPctChange(stockInfo) {
    if (!stockInfo || !stockInfo.stockInfo) return null;
    var info = stockInfo.stockInfo;
    var open = info.regularMarketOpen;
    var price = info.regularMarketPrice;
    if (price === 0 || open === 0) return null;
    var result = ((price-open)/open) * 100.0;
    return result;
  }

  function constructPctDisplayString(pct) {
    if (pct === undefined || pct === null) return null;
    var result = (pct >= 0 ? "+" : "") + pct.toFixed(2) + "%";
    return result;
  }

  function constructPctDisplayColor(pct) {
    if (pct === undefined || pct === null) return null;
    var redOrGreen = pct < 0.0 ? "red" : "green";
    return redOrGreen;
  }

  function calculateSuperDeltaPercent(initialValue, currentValue) {
    if (currentValue === null || initialValue === null) return null;
    var result = ((currentValue - initialValue)/initialValue) * 100.0;
    return result;
  }

  function calculateTotalOwnedValue(symbolsAndCounts, stockInfos) {
    if (symbolsAndCounts === null) return 0;
    if (stockInfos === null) return 0;
    var result = 0;
    symbolsAndCounts.forEach(symbolAndCount => {
      var symbol = symbolAndCount.symbol;
      var ownedCount = symbolAndCount.ownedCount;
      var currentPrice = stockInfos?.find(stockInfo => {return stockInfo.key === symbol})?.stockInfo?.regularMarketPrice ?? 0
      result += ownedCount * currentPrice;
    });
    return result;
  }

  function calculateTotalDeltaSuperValue(symbolsAndCounts, stockInfos) {
    if (symbolsAndCounts === null) return 0;
    if (stockInfos === null) return 0;
    // eslint-disable-next-line no-self-compare
    var superSymbolsAndCounts = symbolsAndCounts.filter(symbolAndCount => symbolAndCount.superCount??0 > 0);
    if (superSymbolsAndCounts.length === 0) return 0;
    if (superSymbolsAndCounts.some(symbolAndCount => stockInfos.find(stockInfo => {return stockInfo.key === symbolAndCount.symbol})?.stockInfo?.regularMarketPrice == null)) return null; // Don't display intermediate sums, only when all fetched
    var result = 0;
    superSymbolsAndCounts.forEach(symbolAndCount => {
      var symbol = symbolAndCount.symbol;
      var superCount = symbolAndCount.superCount??0;
      var superInitValue = symbolAndCount.superInitValue??0;
      var currentPrice = stockInfos.find(stockInfo => {return stockInfo.key === symbol})?.stockInfo?.regularMarketPrice;
      if (currentPrice == null) return; // continue - don't include in total if currentPrice has not been fetched

      result += (currentPrice - superInitValue) * superCount;
    });
    return result;
  }

  useEffect(() => {
    const fetchedData = [];
    props.symbolsAndCounts.forEach(symbolAndCount => {
      var jsonHeaders = new Headers().append('Accept', 'application/json');
      fetch(svcUrl+"?symbol="+symbolAndCount.symbol, jsonHeaders)
      .then(response => {
        return response.json()
      })
      .then(data => {
        fetchedData.push(data.data)
        var newStockInfos = stockInfos.map(stockInfo => {
          return {
            key: stockInfo.key,
            stockInfo: fetchedData.find(fetched => fetched.symbol === stockInfo.key)
          }
        });
        var asx200StockInfo = newStockInfos?.find(stockInfo => stockInfo.key === asx200Symbol);
        setAsx200StockInfo(asx200StockInfo);
        setStockInfos(newStockInfos);
        setTotalOwnedValue(calculateTotalOwnedValue(props.symbolsAndCounts, newStockInfos));
        setTotalDeltaSuperValue(calculateTotalDeltaSuperValue(props.symbolsAndCounts, newStockInfos));
      });
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // empty dependency array so useEffect only runs once when component mounts

  return (
    <div className="App">
      <div style={{height: "1rem"}} />
      <table>
        <thead>
          <tr>
            <td align="center">Symbol</td>
            <td align="center">Value</td>
            <td align="center">Count</td>
            <td align="center">Product</td>
          </tr>
        </thead>
        <tbody>
          {stockInfos.map(stockInfo => {
            var symbol = stockInfo.key;
            var ownedCount = props.symbolsAndCounts.find(symbolAndCount => {return symbolAndCount.symbol === symbol})?.ownedCount??0;
            var superCount = props.symbolsAndCounts.find(symbolAndCount => {return symbolAndCount.symbol === symbol})?.superCount??0;
            var superInitValue = props.symbolsAndCounts.find(symbolAndCount => {return symbolAndCount.symbol === symbol})?.superInitValue??0;
            var currentPrice = stockInfo?.stockInfo?.regularMarketPrice;
            var superInitProduct = superInitValue * superCount;
            var superCurrentProduct = currentPrice == null ? null : currentPrice * superCount;
            var superCurrentProductDelta = superCurrentProduct == null ? null : superCurrentProduct - superInitProduct;
            var superDeltaPercent = calculateSuperDeltaPercent(superInitValue, currentPrice);
            if (ownedCount + superCount === 0) return null;
            return (
              <tr key={symbol}>
                <td align="right"><a className="App-link" href={'https://au.finance.yahoo.com/quote/'+symbol} target="_blank" rel="noopener noreferrer">{symbol}</a></td>
                <td align="right">
                  <span>
                    {stockInfo?.stockInfo == null ? "Loading..." : currentPrice}
                  </span>
                  <div style={superStyle}>
                    {stockInfo?.stockInfo == null ?
                      "" : 
                      superInitValue === 0 ?
                        null :
                        <>
                          <span>(</span>
                          <span>{superInitValue}</span>
                          <span style={{color: superDeltaPercent < 0 ? superNegativeColor : superDeltaPercent > 0 ? superPositiveColor : superColor}}>
                            {" " + constructPctDisplayString(superDeltaPercent)}
                          </span>
                          <span>)</span>
                        </>
                    }
                  </div>
                </td>
                <td align="right">
                  <span>
                    {ownedCount === 0 ? null : ownedCount}
                  </span>
                  <div style={superStyle}>
                    {superCount === 0 ? 
                      null :
                      <>
                        <span>(</span>
                        <span>
                          {superCount}
                        </span>
                        <span>)</span>
                      </>
                    }
                  </div>
                </td>
                <td align="right">
                  <span>
                    {ownedCount === 0 ? null : currencyFormatter.format((currentPrice??0) * ownedCount)}
                  </span>
                  <div style={superStyle}>
                    <div style={{color: superCurrentProduct >= superInitProduct ? superPositiveColor : superNegativeColor}}>
                      {superCount === 0 || superCurrentProduct == null ? null : currencyFormatter.format(superCurrentProduct)}
                    </div>
                    <div>
                      {superCount === 0 ? null : "(" + currencyFormatter.format(superInitProduct) + ")"}
                    </div>
                    <div>
                      {superCount === 0 || superCurrentProductDelta == null ? 
                        null :
                        <>
                          <span>({deltaSymbol}</span>
                          <span style={{color: superCurrentProductDelta >= 0 ? superPositiveColor : superNegativeColor}}>{currencyFormatter.format(superCurrentProductDelta)}</span>
                          <span>)</span>
                        </>
                        }
                    </div>
                  </div>
                </td>
              </tr>
            )
          })}
          {
            <tr>
              <td colSpan="3" className="no-border no-padding" style={{textAlign: "left", verticalAlign: "bottom"}}>
                <div className="asx200-cell">
                  <a className="App-link" href={'https://au.finance.yahoo.com/quote/'+asx200Symbol} target="_blank" rel="noopener noreferrer">{asx200Label}</a>
                  {asx200StockInfo == null ?
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;...</span>
                    :
                    <span style={{color: constructPctDisplayColor(calculateStockInfoPctChange(asx200StockInfo))}}>
                    &nbsp;&nbsp; 
                    {constructPctDisplayString(calculateStockInfoPctChange(asx200StockInfo))}
                    </span>
                  }
                </div>
              </td>
              <td align="right">
                <span>
                  {currencyFormatter.format(totalOwnedValue)}
                </span>
                <div style={superStyle}>
                  {totalDeltaSuperValue == null ?
                    null :
                    <div style={{color: totalDeltaSuperValue < 0 ? superNegativeColor : superColor}}>
                      {deltaSymbol + currencyFormatter.format(totalDeltaSuperValue)}
                    </div>
                  }
                </div>
              </td>
            </tr>
          }
        </tbody>
      </table>
    </div>
  );
}

export default App;
