import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
// import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <App symbolsAndCounts={[
      {key:1, symbol:"CBA.AX", ownedCount:7114, superCount:2000, superInitValue:101.5}, 
      {key:2, symbol:"TLS.AX", ownedCount:4630 + 6200, superCount:59500, superInitValue:4.11},
      {key:3, symbol:"BHP.AX", ownedCount:0, superCount:3500, superInitValue:40.5},
      {key:4, symbol:"VAS.AX", ownedCount:0, superCount:2500, superInitValue:87},
      {key:5, symbol:"^AXJO", ownedCount:0}
    ]} />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
